import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import {NavLink} from 'react-router-dom'

var resumeLink = "https://resume.io/r/vJm93Wl9u"

class NavigationBar extends React.Component
{
    render(){
        return (
            <Navbar variant="light">
                <Navbar.Brand href="/"> 
                    ARYA ASGARI
                </Navbar.Brand>
                <Nav className="ml-auto">
                    <Nav.Link exact as={NavLink} to="/"> Home </Nav.Link>
                    <Nav.Link as={NavLink} to="/about"> About </Nav.Link>
                    <Nav.Link as={NavLink} to="/contact"> Contact </Nav.Link> 
                    <Nav.Link href={resumeLink}> Resume </Nav.Link>
                </Nav>
            </Navbar>
        );
    }
}

export default NavigationBar
