import React from 'react'

import frankieScreenshot from './Images/1268842.jpg'
import soccerGraphic from './Images/soccerGraphic.png'
import androidScreenshot from './Images/androidScreenshot1.png'
import DamianLillard from './Images/DamianLillard.png'
import CanvasMockUp from './Images/CanvasMockUp.png'
import VisaMockUp from './Images/VisaMockUp.png'
import YoutubeScreenshot from './Images/YoutubeScreenshot.png'
import OFLLogo from './Images/OFLLogoTwitter.png'

import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import './Projects.css'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'



const ProjectElement = ({title, subtitle, picture, text, githubLink, siteLink}) => 
    (
            <Card style={{borderRadius: '0rem', borderColor:"#DDDDDD", marginBottom:"5%"}}>   
                <a href={(siteLink) ? siteLink : null}> <Card.Img variant="top" src={picture}ç/> </a>
                <Card.Body>
                    <Card.Title>{title}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted"> {subtitle}</Card.Subtitle>
                    <Card.Text >
                    {text}
                    </Card.Text>
                    {(siteLink) ?
                        <Button variant="outline-dark" href={siteLink} className="firstButton">View Project</Button>
                        : null
                    }
                    {(githubLink) ? 
                        <Button variant="outline-dark"  href={githubLink} className={siteLink ? "otherButton" : "firstButton"}>View Github</Button>
                        : null
                    }
                </Card.Body>
            </Card>
            
    )
    

class ProjectCarousel extends React.Component{

    constructor(props)
    {
        super(props);
        this.state = {entered: null};
    }

    render(){
        return (
            <div>
                <Container>
                    <Row>
                        <Col>
                        < ProjectElement
                            siteLink="http://www.twitter.com/ofl_bot"
                            picture={OFLLogo}
                            title="Fantasy Football Twitter Bot"
                            subtitle="Website"
                            text="A twitter bot using the Twitter & Sleeper API. Ran on AWS Lambda using S3 Buckets."
                        />
                        <ProjectElement 
                            siteLink="http://www.franky-says.surge.sh"
                            githubLink = "http://www.franky-says.surge.sh" 
                            picture={frankieScreenshot} 
                            title="Frank Ocean Random Lyric Generator"
                            subtitle="Website"
                            text="A website that generates a random Frank Ocean Lyrics and allows you to tweet it." />
                       
                       <ProjectElement
                            siteLink="https://drive.google.com/file/d/1Y7-6SRrY5dhhFraJ9WH8DpNVFU0z9f9I/view?usp=sharing"
                            picture={CanvasMockUp}
                            title="Canvas Discussions" 
                            subtitle="UX Redesign"
                            text="A redesign of the classroom program 'Canvas' with a new discussion program, filtering, and settings." />
                        <ProjectElement
                            githubLink="https://github.com/asgaria/CS458-Information-Visualization"
                            picture={DamianLillard}
                            title="NBA Data Visualizations" 
                            subtitle="Data Science"
                            text="Zone shot chart visualization for NBA shot statistics."/>
                        </Col>

                        <Col>
                            <ProjectElement
                                githubLink="http://github.com/asgaria/Soccer-Goal-Time-Visualization"
                                picture={soccerGraphic}
                                title="EPL Goal Time Data Analysis" 
                                subtitle="Data Science"
                                text="A data analysis on the times goals occur in the English Premier League."
                                />

                            <ProjectElement
                                siteLink="https://chrome.google.com/webstore/detail/youtube-with-intent/mkleefijaclbjkahfjchdpphhbglnhdb"
                                subtitle="Chrome Extension"
                                picture ={YoutubeScreenshot}
                                title="Youtube With Intent" 
                                text="A chrome extension that hides the recommended videos on the Youtube homepage to keep you from getting distracted."/>
                        </Col>
                        </Row>

                </Container>

            </div>
        );
    }
}

export default ProjectCarousel