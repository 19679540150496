import React from 'react'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Projects from './Projects.js'
import './index.css'




class Home extends React.Component
{
    render(){
        return (
            <div>
                <Jumbotron style ={{backgroundColor:"#FFFFFF"}} fluid>
                    <Container>
                        <h1> Hey, I'm Arya.</h1>
                        <h1> Here's some projects I've done. </h1>
                    </Container>
                </Jumbotron>
                <Projects />
               
            </div>
        )
    }
}

export default Home