import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
import './About.css'
import photo from './Images/tree.JPG'
import github from './Images/github.png'
import Jumbotron from 'react-bootstrap/Jumbotron'

class About extends React.Component{

    render() {
        return(
            <div>
                <Container className="containerStyle">
                    <Row>
                    <Col>
                    <Jumbotron fluid style={{backgroundColor:"#FFFFFF", fontSize:"1.5rem"}}>
                        <Container>
                            <div className="centerText">
                                <p> Hey, I'm Arya Asgari.  </p>
                                <p> I'm a full stack engineer from Portland, OR.</p>
                                <p> I like Python, React, Graphic Design, and Basketball.</p>
                                <p>Please hire me so I can continue to buy vintage Blazer jerseys.</p>
                                <a href="https://www.github.com/asgaria">
                                    <Image className="imageStyle" src={github} heiight="25%" width="25%" style={{alignItem:"center"}}></Image>
                                </a>
                            </div>
                        </Container>
                    </Jumbotron>
                            
                        </Col>
                        <Col>
                            <Image src={photo} fluid/>
                        </Col>
                        
                    </Row>
                </Container>
                
            </div>    
        )
    }

}

export default About;