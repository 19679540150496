import React from 'react'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'


class Contact extends React.Component{

    render(){
        return(
            <Jumbotron style={{backgroundColor:"#FFFFFF"}} fluid>
                <Container>
                    <h1>Let's chat.</h1>
                    <h1>arya.asgari1@gmail.com</h1>
                </Container>
            </Jumbotron>
        )
    }
}

export default Contact