import React from 'react';
import logo from './logo.svg';
import NavigationBar from './NavigationBar.js'
import Home from './Home.js'
import {BrowserRouter, Route} from 'react-router-dom'
import About from './About.js'
import Projects from './Projects.js'

import './App.css';
import Contact from './Contact.js'
function App() {
  return (
      <BrowserRouter>
          <NavigationBar />
          <Route exact path="/" component={Home}/>
          <Route path="/about" component={About}/>
          <Route path="/contact" component={Contact}/>
      </BrowserRouter>    
      
    );
}

export default App;
